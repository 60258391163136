export const contentTableConfig = {
  title: '',
  propList: [
    {
      prop: 'module',
      label: 'general.module',
      slotName: 'module'
    },
    {
      prop: 'operator',
      label: 'general.operator'
    },
    {
      prop: 'resource',
      label: 'common.app-user-resource'
    },
    {
      prop: 'operation',
      label: 'general.operation',
      slotName: 'operation'
    },
    {
      prop: 'createdTime',
      slotName: 'formatZeroTime',
      label: 'general.operation-time'
    }
  ],
  showIndexColumn: true
}
