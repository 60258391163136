export const searchFormConfig = {
  formItems: [
    {
      field: 'operation',
      type: 'input',
      label: 'general.operation'
    },
    {
      field: 'module',
      type: 'autocomplete',
      tableList: [],
      label: 'general.module'
    },
    {
      field: 'createTime',
      type: 'datepicker',
      label: 'general.operation-time',
      startPlaceholder: 'general.start-time',
      endPlaceholder: 'general.end-time',
      otherOptions: {
        type: 'daterange'
      }
    }
  ]
}
