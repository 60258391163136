<template>
  <div class="applications">
    <page-search
      ref="pageSearchRef"
      :searchFormConfig="searchFormConfigRef"
      :showBackBtn="true"
      :pageTitle="$t('general.router-user-log')"
      @resetBtnClick="handleResetClick"
      @queryBtnClick="handleQueryClick"
    >
    </page-search>
    <page-content
      style="margin-top: 10px"
      ref="pageContentRef"
      v-bind="tableOptionsConfig"
      @pageInfoChange="pageInfoChangeAction"
    >
      <template #module="scope">
        {{ $t(scope.row.module) }}
      </template>
      <template #operation="scope">
        {{ $t(scope.row.operation) }}
      </template>
    </page-content>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useRoute } from 'vue-router'
import { formatDateTime } from '@/utils/util.js'
import { moduleList } from '@/utils/constant/const.js'
import pageSearch from '@/components/PageSearch/page-search'
import pageContent from '@/components/PageContent/page-content.vue'

import { searchFormConfig } from './config/log.search'
import { contentTableConfig } from './config/log.table'

const pageContentRef = ref('')
const pageSearchRef = ref('')

const routes = useRoute()
const name = routes.params.name
const pageInitialParams = {
  createdBy: name,
  operator: name
}

// 表格基础配置项
const tableOptions = {
  moduleName: 'user', // 所属的模块
  pageName: 'userLogs', // 所属的页面
  contentTableConfig, // 表格列的基础配置
  isShowCreateSlot: false, // 是否显示添加按钮的插槽
  pageInitialQuery: pageInitialParams,
  pageAction: 'user/getUserLogsList', // 获取表格的Action
  pageCountGetter: 'user/pageListCount', // 获取表格记录数据总和getters
  pageListGetter: 'user/pageListData' // 获取表格列表数据getters
}
const tableOptionsConfig = computed(() => tableOptions)

// 后续接口写好再恢复
const searchFormConfigRef = computed(() => {
  const moduleListItem = searchFormConfig.formItems.find(
    (item) => item.field === 'module'
  )
  const localeGroups = []
  for (var item in moduleList) {
    localeGroups.push({ value: moduleList[item] })
  }
  moduleListItem.tableList = localeGroups
  return searchFormConfig
})

const handleQueryClick = (queryInfo) => {
  // 调用page-search中的getPageData方法
  const queryData = formateData(queryInfo)
  pageContentRef.value.getPageData(queryData)
}

const handleResetClick = () => {
  pageContentRef.value.getPageData(pageInitialParams)
}

const transformModule = (module) => {
  for (var item in moduleList) {
    if (moduleList[item] === module) {
      var formatValue = 'common.' + item
      return formatValue
    }
  }
  return ''
}
const formateData = (formData) => {
  const formateObejct = {
    ...pageInitialParams,
    startDateTime: formData.createTime
      ? formatDateTime(formData.createTime[0], 'yyyy-MM-dd')
      : '',
    endDateTime: formData.createTime
      ? formatDateTime(formData.createTime[1], 'yyyy-MM-dd')
      : '',
    operation: formData.operation,
    module: transformModule(formData.module)
  }
  return formateObejct
}

const pageInfoChangeAction = () => {
  const formData = pageSearchRef.value.getCurrentFieldValues()
  pageContentRef.value.getPageData(formateData(formData))
}
</script>
